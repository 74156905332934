import { TextureLoader } from "three"

class Loader {
    constructor() {
        this.loader = new TextureLoader()
    }

    load(src) {
        return new Promise(resolve => {
            this.loader.load(src, resolve)
        })
    }
}

export default new Loader()
