// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-pricing-page-js": () => import("./../../../src/templates/pricingPage.js" /* webpackChunkName: "component---src-templates-pricing-page-js" */),
  "component---src-templates-primary-landing-page-js": () => import("./../../../src/templates/primaryLandingPage.js" /* webpackChunkName: "component---src-templates-primary-landing-page-js" */),
  "component---src-templates-product-landing-page-js": () => import("./../../../src/templates/productLandingPage.js" /* webpackChunkName: "component---src-templates-product-landing-page-js" */),
  "component---src-templates-product-single-page-js": () => import("./../../../src/templates/productSinglePage.js" /* webpackChunkName: "component---src-templates-product-single-page-js" */),
  "component---src-templates-secondary-landing-page-js": () => import("./../../../src/templates/secondaryLandingPage.js" /* webpackChunkName: "component---src-templates-secondary-landing-page-js" */),
  "component---src-templates-standard-page-js": () => import("./../../../src/templates/standardPage.js" /* webpackChunkName: "component---src-templates-standard-page-js" */),
  "component---src-templates-tertiary-landing-page-js": () => import("./../../../src/templates/tertiaryLandingPage.js" /* webpackChunkName: "component---src-templates-tertiary-landing-page-js" */)
}

