import vertexShader from "./scanning.vert"
import fragmentShader from "./scanning.frag"

export default {
    uniforms: {
        shapeTex: { value: null },
        questionTex: { value: null },
        fileTex: { value: null },
        nukeTex: { value: null },
        anim: { value: 0 },
        threatVal: { value: 0 },
        fileAnim: { value: 0 },
        scanAnim: { value: 0 },
        resultAnim: { value: 0 },
        color: { value: [1, 1, 1] },
        time: { value: 0 },
    },
    vertexShader,
    fragmentShader,
    transparent: true,
}
