import {
    Scene,
    PerspectiveCamera,
    Mesh,
    ShaderMaterial,
    Vector2,
    Object3D,
    WebGLRenderTarget,
    PlaneGeometry,
    UniformsUtils,
    Vector3,
    Geometry,
} from "three"

import { relativeProgress as rp, easing, clamp } from "../../util/math"
import scanningShader from "./shaders/scanningShader"
import antialiasShader from "./shaders/antialiasShader"

import gridModel from "./models/scan-hex2.obj"
import pointModel from "./models/cube.obj"

import modelLoader from "./modelLoader"

import imageLoader from "./imageLoader"
import triangleImage from "./images/triangle.png"
import circleImage from "./images/circle.png"
import squareImage from "./images/square.png"
import fileImage from "./images/file.png"
import questionImage from "./images/question.png"
import tickImage from "./images/tick.png"
import threatImage from "./images/threat.png"

class Visual1 {
    fov = 45

    canRender = false
    pointScale = 0.005

    rotationObj = new Object3D()
    hasInit = false
    startTime = 0
    timeout = null
    dims = null
    mouse = new Vector2(0)
    animMouse = new Vector2(0)
    currentShape = 0
    shapes = []
    isThreat = true
    nuke = null
    tick = null

    constructor({ width, height }, renderer) {
        this.renderer = renderer
        this.scene = new Scene()

        this.camera = new PerspectiveCamera(this.fov, 1, 1, 2000)

        this.camera.position.z = 3
        this.camera.position.x = 0
        this.scene.add(this.rotationObj)

        this.rotationObj.rotation.x = Math.PI * 0.5

        this.initRenderPlane(width, height)

        window.addEventListener("mousemove", e => {
            const { clientX, clientY } = e
            const { innerWidth, innerHeight } = window

            this.mouse.y = (clientX / innerWidth - 0.5) * -1
            this.mouse.x = clientY / innerHeight - 0.5
        })
    }

    loadModel = model => {
        return new Promise(resolve => {
            modelLoader.load(model, resolve)
        })
    }

    init = async () => {
        if (this.hasInit) return
        this.hasInit = true

        const [
            triangleTex,
            circleTex,
            squareTex,
            fileTex,
            nukeTex,
            questionTex,
            tickTex,
            p,
            g,
        ] = await Promise.all([
            imageLoader.load(triangleImage),
            imageLoader.load(circleImage),
            imageLoader.load(squareImage),
            imageLoader.load(fileImage),
            imageLoader.load(threatImage),
            imageLoader.load(questionImage),
            imageLoader.load(tickImage),

            this.loadModel(pointModel),
            this.loadModel(gridModel),
        ])

        this.shapes = [triangleTex, circleTex, squareTex]
        this.tick = tickTex
        this.nuke = nukeTex

        this.pointGeo = new Geometry().fromBufferGeometry(
            p.children[0].geometry
        )

        const grid = g.children[0]

        // small transform ---
        grid.rotation.x = Math.PI / 2
        // grid.scale.x = grid.scale.y = grid.scale.z = 5
        grid.updateMatrix()
        grid.geometry.applyMatrix(grid.matrix)
        // ----------------

        this.gridGeo = new Geometry().fromBufferGeometry(grid.geometry)

        // console.log(this.gridGeo)

        this.point = new Mesh(this.pointGeo)
        this.createPoints()

        this.material.uniforms.threatVal.value = 0

        this.material.uniforms.shapeTex.value = this.shapes[0]
        this.material.uniforms.fileTex.value = fileTex
        this.material.uniforms.nukeTex.value = nukeTex
        this.material.uniforms.questionTex.value = questionTex

        // console.log(cubeTex)

        this.canRender = true
        this.render(this.renderer)

        setInterval(this.anim, 4500)

        this.anim()
    }

    initRenderPlane = (width, height) => {
        const targetWidth = width * 0.6
        const targetHeight = targetWidth
        const { uniforms: u, vertexShader, fragmentShader } = antialiasShader

        const uniforms = UniformsUtils.clone(u)

        this.renderTarget = new WebGLRenderTarget(targetWidth, targetHeight, {
            // depthBuffer: false,
            // stencilBuffer: false,
        })

        const geo = new PlaneGeometry(1, 1)

        uniforms.tDiffuse.value = this.renderTarget.texture

        const mat = new ShaderMaterial({
            uniforms,
            vertexShader,
            fragmentShader,
            transparent: true,
        })

        this.renderPlane = new Mesh(geo, mat)

        this.renderPlane.scale.x = (1 / width) * targetWidth
        this.renderPlane.scale.y = (1 / height) * targetHeight
    }

    createPoints = () => {
        const { gridGeo } = this

        this.material = new ShaderMaterial(scanningShader)
        this.points = new Mesh(new Geometry(), this.material)

        for (let i = 0; i < gridGeo.vertices.length; i++) {
            const x = gridGeo.vertices[i].x
            const y = gridGeo.vertices[i].y
            const z = gridGeo.vertices[i].z

            this.addPoint(x, y, z)
        }

        this.points.doubleSided = true

        this.points.rotation.x = -Math.PI / 2
        this.rotationObj.add(this.points)

        // console.log(this.points)
    }

    minx = Number.POSITIVE_INFINITY
    miny = Number.POSITIVE_INFINITY

    maxx = Number.NEGATIVE_INFINITY
    maxy = Number.NEGATIVE_INFINITY

    addPoint = (x, y, z) => {
        const { point } = this
        point.position.set(x, y, z)
        point.scale.set(this.pointScale, this.pointScale, 0.1)
        point.lookAt(new Vector3(x, y, z - 1))
        point.updateMatrix()

        this.minx = Math.min(x, this.minx)
        this.miny = Math.min(y, this.miny)

        this.maxx = Math.max(x, this.maxx)
        this.maxy = Math.max(y, this.maxy)

        for (let i = 0; i < point.geometry.faceVertexUvs[0].length; i++) {
            for (
                let j = 0;
                j < point.geometry.faceVertexUvs[0][i].length;
                j++
            ) {
                const u = x / 2 + 0.5
                const v = y / 2 + 0.5
                point.geometry.faceVertexUvs[0][i][j] = new Vector2(u, v)
            }
        }

        this.points.geometry.merge(point.geometry, point.matrix)
    }

    resize = (width, height) => {
        this.width = width
        this.height = height
        // this.camera.aspect = this.width / this.height
        // this.camera.updateProjectionMatrix()

        if (!this.dims) return

        const targetWidth = this.dims.width * 2
        const targetHeight = targetWidth

        this.renderPlane.material.uniforms.resolution.value = new Vector2(
            1 / targetWidth,
            1 / targetHeight
        )

        this.renderTarget.setSize(targetWidth, targetHeight)
    }

    setPos = data => {
        if (!data) return
        const { innerWidth, innerHeight } = window
        const { width, height, top, left } = data

        if (!this.dims) {
            this.dims = data
            this.resize(innerWidth, innerHeight)
        }

        this.dims = data

        const x = (left + width / 2 - innerWidth / 2) / innerWidth

        this.renderPlane.position.x = x
        this.renderPlane.scale.x = (1 / innerWidth) * width
        this.renderPlane.scale.y = (1 / innerHeight) * width
    }

    anim = () => {
        this.startTime = window.performance.now()

        this.currentShape = (this.currentShape + 1) % this.shapes.length
        this.material.uniforms.shapeTex.value = this.shapes[this.currentShape]

        // this.isThreat = false
        this.isThreat = !this.isThreat //Math.random() > 0.7

        this.material.uniforms.threatVal.value = this.isThreat ? 1 : 0
        this.material.uniforms.nukeTex.value = this.isThreat
            ? this.nuke
            : this.tick
    }

    update = (progress, posData) => {
        if (!this.canRender) return

        this.setPos(posData)

        const op = rp(progress, 0, 0.1) - rp(progress, 0.5, 0.8)

        this.renderPlane.material.uniforms.opacity.value = op

        const t = window.performance.now()
        const six_secs = clamp((t - this.startTime) * 0.001, 0, 4)

        this.points.material.uniforms.time.value = t * 0.01

        this.points.material.uniforms.anim.value = rp(six_secs, 0, 0.4)

        this.points.material.uniforms.scanAnim.value = rp(six_secs, 0.8, 2.3)
        this.points.material.uniforms.resultAnim.value = rp(six_secs, 2.3, 4.5)

        const prog = easing.outQuart(rp(progress, 0, 1))

        this.camera.position.x = 0
        this.camera.position.z = 3
        this.camera.position.y = 0.2 - 0.4 * prog

        this.camera.lookAt(new Vector3(0, 0, 0))

        this.renderPlane.position.y =
            -0.05 + 0.1 * progress + rp(progress, 0.6, 1) * 0.7
        // this.rotationObj.position.y = 5 * prog

        this.animMouse.x += (this.mouse.x - this.animMouse.x) * 0.1
        this.animMouse.y += (this.mouse.y - this.animMouse.y) * 0.1

        this.rotationObj.rotation.z = this.animMouse.y * 0.3
        this.rotationObj.rotation.x = Math.PI * 0.5 + this.animMouse.x * 0.3
    }

    render = renderer => {
        if (!this.canRender) return

        // console.log("progress")

        renderer.setRenderTarget(this.renderTarget)
        renderer.clear()
        renderer.render(this.scene, this.camera)
        renderer.setRenderTarget(null)
    }
}

export default Visual1
