import vertexShader from "./test.vert"
import fragmentShader from "./usa.frag"

export default {
    uniforms: {
        tDiffuse: { value: null },
    },
    vertexShader,
    fragmentShader,
    transparent: true,
}
