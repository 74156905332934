module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"open-avn","short_name":"open-avn","start_url":"/","display":"minimal-ui","icon":"src/images/defenseark-icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d94ad83c7087d7bf9bc6e4b45b2b4aa4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/vercel/path0/src/components/Layout.js"},
    },{
      plugin: require('../plugins/gatsby-plugin-graphql-runtime/gatsby-browser.js'),
      options: {"plugins":[],"url":"http://35.190.163.14/api","typeName":"CRAFT","fieldName":"craft"},
    },{
      plugin: require('../../../var/task/node_modules/@vercel/gatsby-plugin-vercel-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
