import React, { useEffect, useState } from "react"
import { hot } from "react-hot-loader/root"
import { TransitionGroup, Transition } from "react-transition-group"
import "../styles/reset.scss"
import "../styles/style.scss"
import { parse } from "query-string"

import WebGLContext, { WebGLProvider } from "./contexts/WebGLContext"
import WebGLAnimations from "./WebGL/WebGLAnimations"

const timeout = 300
const getTransitionStyles = {
    entering: {
        // position: "absolute",
        opacity: 0,
    },
    entered: {
        transition: `opacity ${timeout}ms ease-in-out`,
        opacity: 1,
    },
    exiting: {
        transition: `all ${timeout}ms ease-in-out`,
        opacity: 0,
    },
}

const Layout = ({ children, location, pageContext, ...props }) => {
    const [previewComponent, setPreviewComponent] = useState(null)
    const [previewParams, setPreviewParams] = useState(null)
    const [isChrome, setIsChrome] = useState(false);
    useEffect(() => {
        setIsChrome(!!window.chrome);
    },[]);
    useEffect(() => {
        ;(async () => {
            const queryParams = parse(window.location.search)
            // console.log(window.location)
            if (queryParams && queryParams["x-craft-token"]) {
                const Previewer = await import("gatsby-plugin-graphql-runtime")
                const pageQuery = queryParams["entryType"]
                    ? Previewer.locatePageQueryByType(queryParams["entryType"])
                    : Previewer.locatePageQuery(pageContext)
                const id = queryParams["entryId"]
                    ? parseInt(queryParams["entryId"], 10)
                    : pageContext.id

                // console.log(pageQuery, pageContext)
                if (pageQuery) {
                    setPreviewParams({
                        pageQuery,
                        headers: {
                            "x-craft-token": queryParams["x-craft-token"],
                        },
                        pageContext: {
                            ...pageContext,
                            id: id,
                            entryType: queryParams["entryType"],
                        },
                    })
                    setPreviewComponent({
                        RuntimeDataProvider: Previewer.RuntimeDataProvider,
                    })
                }
            }
        })()
    }, [location, pageContext])
    let output
    if (previewComponent && previewParams) {
        output = (
            <previewComponent.RuntimeDataProvider {...previewParams}>
                {children}
            </previewComponent.RuntimeDataProvider>
        )
    } else {
        output = children
    }
    return (
        <div className={isChrome ? "is-chrome" : ""}>
            <WebGLProvider>
                <WebGLAnimations />
                <TransitionGroup>
                    <Transition
                        key={location.pathname}
                        timeout={{ enter: timeout, exit: timeout }}
                    >
                        {status => {
                            return (
                                <div
                                    className={status}
                                    style={{
                                        ...getTransitionStyles[status],
                                    }}
                                >
                                    {output}
                                </div>
                            )
                        }}
                    </Transition>
                </TransitionGroup>
            </WebGLProvider>
        </div>
    )
}
export default process.env.NODE_ENV === "development" ? hot(Layout) : Layout
