import {
    Scene,
    Mesh,
    PlaneGeometry,
    ShaderMaterial,
    DataTexture,
    RGBAFormat,
    FloatType,
} from "three"

import FBO from "./FBO"
import OrthCam from "./OrthCam"

class GPULoop {
    _currentFBO = 0

    constructor({
        textureFilter = null,
        frag,
        vert,
        uniforms = {},
        width = window.innerWidth,
        height = window.innerHeight,
        input = false,
    }) {
        let texture = input
        this.input = input
        // if(!input){
        //     const data = new Float32Array( 4 * width * height );
        //     data.fill(0);
        //     this.dataTexture = new DataTexture( data, width, height, RGBAFormat, FloatType );
        //     this.dataTexture.needsUpdate = true;

        //     texture = this.dataTexture;
        // }

        // this.texture = texture;

        this.startSize = { width, height }
        const geo = new PlaneGeometry(width, height)
        const mat = new ShaderMaterial({
            uniforms: {
                ...uniforms,
                dataIn: { value: texture },
                time: { value: (window.performance.now() % 5000) / 5000 },
            },
            vertexShader: vert,
            fragmentShader: frag,
        })

        this.mesh = new Mesh(geo, mat)

        const minFilter = textureFilter
        const magFilter = textureFilter

        this.minFilter = minFilter
        this.magFilter = magFilter

        this.FBOs = [
            new FBO({ width, height, minFilter, magFilter }),
            new FBO({ width, height, minFilter, magFilter }),
        ]

        if (!texture)
            this.mesh.material.uniforms.dataIn.value = this.FBOs[0].texture

        this.scene = new Scene()
        this.scene.add(this.mesh)
        this.cam = new OrthCam(width, height)
    }

    resize = (width, height) => {
        const { minFilter, magFilter } = this

        this.FBOs[0].dispose()
        this.FBOs[1].dispose()

        this.FBOs = [
            new FBO({ width, height, minFilter, magFilter }),
            new FBO({ width, height, minFilter, magFilter }),
        ]
        this.mesh.material.uniforms.dataIn.value = this.FBOs[
            this._currentFBO
        ].texture

        if (this.input) this.mesh.material.uniforms.dataIn.value = this.imput

        this.cam.resize(width, height)

        this.mesh.scale.x = width / this.startSize.width
        this.mesh.scale.y = height / this.startSize.height
    }

    get uniforms() {
        return this.mesh.material.uniforms
    }

    get target() {
        return (this._target = this.FBOs[this._currentFBO])
    }

    // set target (val){
    //     this.mesh.material.uniforms. = val;
    // }

    setSize() {}

    render = renderer => {
        this._currentFBO = (this._currentFBO + 1) % 2
        const output = this.FBOs[this._currentFBO]

        renderer.setRenderTarget(output)
        renderer.render(this.scene, this.cam)
        renderer.setRenderTarget(null)

        this.mesh.material.uniforms.dataIn.value = output.texture
        this.mesh.material.uniforms.time.value =
            (window.performance.now() % 5000) / 5000

        return output.texture
    }
}

export default GPULoop
