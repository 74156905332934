import vertexShader from "./particleDrift.vert"
import fragmentShader from "./particleDrift.frag"

export default {
    uniforms: {
        tDiffuse: {
            value: null,
        },
        movement: {
            value: null,
        },

        time: {
            value: 0,
        },
        bounds: {
            value: [0, 0],
        },
    },
    vertexShader,
    fragmentShader,
    transparent: true,
}
