import {
    Scene,
    PerspectiveCamera,
    WebGLRenderer,
    PlaneGeometry,
    Mesh,
    ShaderMaterial,
    OrthographicCamera,
    WebGLRenderTarget,
    Vector2,
    UniformsUtils,
} from "three"
import Cover from "./Cover"

import Crowdsourced from "./Crowdsourced"
import Scanning from "./Scanning"

import { relativeProgress, clamp } from "../../util/math"

import stageShaders from "./shaders/stage"
import driftShaders from "./shaders/drift"
import antialiasShader from "./shaders/antialiasShader"

import ParticleDrift from "./ParticleDrift"

import FBO from "./ParticleDrift/FBO"

class Stage {
    fov = 45
    animProg = 0
    cover = null
    canRender = false
    cleared = false
    initFired = false
    initTried = false
    deactivated = true

    constructor({ el }) {
        // window.addEventListener("resize", this.resize)

        const { innerWidth: width, innerHeight: height } = window

        this.width = window.innerWidth
        this.height = window.innerHeight

        this.renderer = new WebGLRenderer({
            canvas: el,
            antialias: false,
            alpha: false,
        })

        this.renderer.setSize(this.width, this.height)
        this.renderer.autoClear = false
        this.renderer.setPixelRatio(1)
        this.renderer.setClearColor(0x1b1839, 0)
        this.renderer.clear()

        this.pd = new ParticleDrift()
        this.target = new FBO({
            // width: window.innerWidth * 2,
            // height: window.innerHeight * 2,
        })
        // return

        this.cover = new Cover({ width, height }, this.renderer)
        this.crowdsourced = new Crowdsourced({ width, height }, this.renderer)
        this.scanning = new Scanning({ width, height }, this.renderer)

        this.scene = new Scene()

        const { uniforms: u, vertexShader, fragmentShader } = antialiasShader

        const uniforms = UniformsUtils.clone(u)
        uniforms.resolution.value = new Vector2(1 / width, 1 / height)
        uniforms.tDiffuse.value = this.target.texture

        this.geo = new PlaneGeometry(1, 1)
        this.material = new ShaderMaterial({
            uniforms,
            vertexShader, //: driftShaders.vertexShader,
            fragmentShader, //: driftShaders.fragmentShader,
            transparent: true,
        })

        // console.log(this.material)

        // this.driftGeo = new PlaneGeometry(1, 1)
        // this.driftMaterial = new ShaderMaterial({
        //     ...driftShaders,
        //     transparent: true,
        // })
        // this.driftMaterial.uniforms.tDiffuse.value = this.target.texture
        // this.driftPlane = new Mesh(this.driftGeo, this.driftMaterial)
        // this.driftPlane.position.z = -1.65

        // this.material.uniforms.tDiffuse.value = this.target.texture

        this.plane = new Mesh(this.geo, this.material)
        this.camera = new OrthographicCamera(-0.5, 0.5, 0.5, -0.5, 1, 10)
        this.plane.position.z = -1.75

        // console.log(this.plane, this.camera)

        this.scene.add(this.plane, this.camera) //this.driftPlane,

        this.crowdsourced.renderPlane.position.z = -1.5
        this.scene.add(this.crowdsourced.renderPlane)

        this.scanning.renderPlane.position.z = -1.25
        this.scene.add(this.scanning.renderPlane)
    }

    init = async () => {
        // return

        const { width, height } = this

        this.initTried = true

        this.deactivated = false

        if (width < 1024) return

        this.initFired = true
        this.renderer.clear()

        await Promise.all([
            this.cover.init(),
            this.crowdsourced.init(),
            this.scanning.init(),
        ])

        this.resize()

        this.canRender = true
    }

    deactivate = () => {
        this.deactivated = true
    }

    update = (prog, max, tileDims) => {
        // return

        this.animProg = prog

        if (this.animProg >= max && this.stopRender) return
        if (this.animProg >= max && !this.stopRender) this.stopRender = true
        else if (this.animProg < max) this.stopRender = false

        this.updateCover(this.animProg)
        this.updateFader(this.animProg)

        this.pd.update(this.renderer)

        if (window.innerWidth >= 1024) {
            this.updateCrowdsourced(this.animProg, tileDims[0])
            this.updateScanning(this.animProg, tileDims[1])
        }

        if (this.stopRender) this.write(true)
    }

    updateFader = prog => {
        let op = relativeProgress(this.coverProg, 0.3, 1)

        this.material.uniforms.opacity.value = op

        // console.log(opacity)

        // this.material.uniforms.colorChange.value = 0
    }

    updateCover = prog => {
        this.coverProg = relativeProgress(prog, 0, 1)
        if (this.coverProg >= 1) return

        this.cover.update(this.coverProg)
    }

    updateCrowdsourced = (prog, dims) => {
        this.crowdsourcedProg = relativeProgress(prog, 0.8, 1.8)
        if (this.crowdsourcedProg % 1 === 0) return

        this.crowdsourced.update(this.crowdsourcedProg, dims)
    }

    updateScanning = (prog, dims) => {
        this.scanningProg = relativeProgress(prog, 1.7, 3)
        if (this.scanningProg % 1 === 0) return

        this.scanning.update(this.scanningProg, dims)
    }

    write = (force = false) => {
        // return

        if ((this.stopRender && !force) || !this.canRender) {
            if (!this.cleared) {
                this.cleared = true
                this.renderer.render(this.scene, this.camera)
                this.renderer.clear()
            }

            return
        }

        const small = window.innerWidth < 1024
        this.cleared = false

        this.crowdsourced.renderPlane.visible = false
        this.scanning.renderPlane.visible = false

        this.renderer.clear()

        if (this.coverProg < 1) {
            this.pd.render(this.renderer)
            this.cover.render(this.renderer)
        }

        this.pd.render(this.renderer, this.target)

        this.renderer.clearDepth()

        if (this.crowdsourcedProg > 0 && this.crowdsourcedProg < 1) {
            // this.pd.render(this.renderer)
            this.crowdsourced.render(this.renderer)
            this.crowdsourced.renderPlane.visible = true
        }

        if (this.scanningProg > 0 && this.scanningProg < 1) {
            // console.log("here")
            this.scanning.render(this.renderer)
            this.scanning.renderPlane.visible = true
        }

        // this.camera.top = 0.5 - relativeProgress(this.animProg, 2.5, 3) * 0.2
        // this.camera.bottom =
        //     -0.5 - relativeProgress(this.animProg, 2.5, 3) * 0.2

        // this.camera.updateProjectionMatrix()

        this.renderer.render(this.scene, this.camera)
    }

    resize = () => {
        // return

        this.width = window.innerWidth
        this.height = window.innerHeight

        if (this.deactivated) return

        // console.log("resizing")

        if (this.initTried && !this.initFired && this.width >= 1024) {
            this.init()
            return
        }

        this.target.setSize(this.width, this.height)
        this.pd.resize(this.width, this.height)

        this.renderer.setSize(this.width, this.height)

        this.cover.resize(this.width, this.height)
        this.crowdsourced.resize(this.width, this.height)
        this.scanning.resize(this.width, this.height)
    }
}

export default Stage
