import {
    Scene,
    PerspectiveCamera,
    Color,
    Mesh,
    UniformsUtils,
    ImageUtils,
    ShaderMaterial,
    Vector3,
    Vector2,
    FaceColors,
    Geometry,
    Object3D,
    RepeatWrapping,
    WebGLRenderTarget,
    PlaneGeometry,

    // OBJLoader,
} from "three"

import imageLoader from "./imageLoader"

import gridModel from "./models/usa4.obj"
import pointModel from "./models/cube.obj"

import worldDataSample from "./images/us-pop3.png"
import usaMap from "./images/usa-map.png"

import crowdShader from "./shaders/crowdShader"
import modelLoader from "./modelLoader"

import antialiasShader from "./shaders/antialiasShader"
import usaShader from "./shaders/usaShader"

import testShaders from "./shaders/test"

import { relativeProgress as rp, easing, clamp } from "../../util/math"

class Crowdsourced {
    fov = 45
    gridDensity = 6
    pointType = "hex"
    pointScale = 0.05
    pointExtrudeRange = [0.01, 70]

    distance = 80000
    distanceTarget = 4000
    curZoomSpeed = 0
    rotation = { x: 0, y: 0 }
    canRender = false
    dims = null

    rotationObj = new Object3D()
    hasInit = false

    timeout = null

    mouse = new Vector2(0)
    animMouse = new Vector2(0)

    constructor({ width, height }, renderer) {
        this.renderer = renderer
        this.scene = new Scene()
        // this.scene.background = new Color(0x1b1839)
        // this.atmosphereScene = new Scene()

        this.camera = new PerspectiveCamera(this.fov, 1, 1, 2000)
        this.camera.position.z = 30
        this.camera.position.x = 0
        this.camera.position.y = 1
        // this.rotationObj.rotation.x = -Math.PI / 2

        this.initRenderPlane(width, height)

        this.scene.add(this.rotationObj)

        window.addEventListener("mousemove", e => {
            const { clientX, clientY } = e
            const { innerWidth, innerHeight } = window

            this.mouse.x = (clientX / innerWidth - 0.5) * -1
            this.mouse.y = clientY / innerHeight - 0.5
        })
    }

    initRenderPlane = (width, height) => {
        const targetWidth = width * 0.6
        const targetHeight = targetWidth
        const { uniforms: u, vertexShader, fragmentShader } = antialiasShader

        const uniforms = UniformsUtils.clone(u)

        // console.log(targetWidth, targetHeight)
        this.renderTarget = new WebGLRenderTarget(targetWidth, targetHeight, {
            // depthBuffer: false,
            // stencilBuffer: false,
        })

        const geo = new PlaneGeometry(1, 1)

        uniforms.tDiffuse.value = this.renderTarget.texture

        const mat = new ShaderMaterial({
            uniforms,
            vertexShader,
            fragmentShader,
            transparent: true,
        })

        this.renderPlane = new Mesh(geo, mat)

        this.renderPlane.scale.x = (1 / width) * targetWidth
        this.renderPlane.scale.y = (1 / height) * targetHeight
    }

    initMapPlane = tex => {
        const geo = new PlaneGeometry(1, 1)
        const material = new ShaderMaterial(usaShader)

        material.uniforms.tDiffuse.value = tex

        const mesh = new Mesh(geo, material)

        mesh.scale.x = 28.4
        mesh.scale.y = 18

        mesh.position.x = 0.5
        mesh.position.z = 0.7

        mesh.rotation.x = -Math.PI * 0.5

        this.rotationObj.add(mesh)
    }

    init = async () => {
        if (this.hasInit) return
        this.hasInit = true

        const [p, g, usaTex] = await Promise.all([
            this.loadModel(pointModel),
            this.loadModel(gridModel),
            imageLoader.load(usaMap),
        ])

        this.pointGeo = new Geometry().fromBufferGeometry(
            p.children[0].geometry
        )

        const grid = g.children[0]

        // // large transform ---
        // grid.rotation.z = Math.PI
        // grid.updateMatrix()
        // grid.geometry.applyMatrix(grid.matrix)
        // // ----------------

        // small transform ---
        grid.rotation.z = Math.PI
        grid.scale.x = grid.scale.y = grid.scale.z = 5
        grid.updateMatrix()
        grid.geometry.applyMatrix(grid.matrix)
        // ----------------

        this.gridGeo = new Geometry().fromBufferGeometry(grid.geometry)

        this.point = new Mesh(this.pointGeo)

        this.createPoints()
        this.initMapPlane(usaTex)

        this.canRender = true

        this.resize(window.innerWidth, window.innerHeight)

        this.render(this.renderer)

        setInterval(this.infect, 2500)
        this.infect()
    }

    infect = () => {
        this.infectStart = window.performance.now()
        this.infectActive = true

        this.infectPoint = new Vector2(
            Math.random() * 0.6 + 0.2,
            Math.random() * 0.6 + 0.2
        )

        this.material.uniforms.infectPoint.value = this.infectPoint

        // console.log(this.infectPoint)
    }

    infectUpdate = () => {
        if (!this.infectActive) return
        const lapsed = window.performance.now() - this.infectStart
        const infectVal = clamp(lapsed * 0.00025, 0, 1)
        this.material.uniforms.infect.value = infectVal

        if (infectVal == 1) this.infectActive = false

        // console.log(infectVal)
    }

    loadModel = model => {
        return new Promise(resolve => {
            modelLoader.load(model, resolve)
        })
    }

    createPoints = () => {
        const { gridGeo } = this
        const { vertexShader, fragmentShader, uniforms: u } = crowdShader
        const uniforms = UniformsUtils.clone(u)

        uniforms.textureData.value = ImageUtils.loadTexture(worldDataSample)
        uniforms.textureData.value.wrapS = uniforms.textureData.value.wrapT = RepeatWrapping

        uniforms["extrudeMin"].value = this.pointExtrudeRange[0]
        uniforms["extrudeMax"].value = this.pointExtrudeRange[1]

        this.material = new ShaderMaterial({
            uniforms,
            vertexShader,
            fragmentShader,
            color: 0xffffff,
            vertexColors: FaceColors,
        })

        this.points = new Mesh(new Geometry(), this.material)

        for (let i = 0; i < gridGeo.vertices.length; i++) {
            const x = gridGeo.vertices[i].x
            const y = gridGeo.vertices[i].y
            const z = gridGeo.vertices[i].z

            this.addPoint(x, y, z)
        }

        this.points.doubleSided = true

        this.points.rotation.x = -Math.PI / 2
        this.rotationObj.add(this.points)

        // console.log(this.minx, this.maxx, this.miny, this.maxy)
    }

    minx = Number.POSITIVE_INFINITY
    miny = Number.POSITIVE_INFINITY

    maxx = Number.NEGATIVE_INFINITY
    maxy = Number.NEGATIVE_INFINITY

    addPoint = (x, y, z) => {
        const { point } = this
        point.position.set(x, y, z)
        point.scale.set(this.pointScale, this.pointScale, 0.1)
        point.lookAt(new Vector3(x, y, z - 1))
        point.updateMatrix()

        this.minx = Math.min(x, this.minx)
        this.miny = Math.min(y, this.miny)

        this.maxx = Math.max(x, this.maxx)
        this.maxy = Math.max(y, this.maxy)

        for (let i = 0; i < point.geometry.faceVertexUvs[0].length; i++) {
            for (
                let j = 0;
                j < point.geometry.faceVertexUvs[0][i].length;
                j++
            ) {
                const u = (x + 15) / 32
                const v = (y + 10) / 18
                point.geometry.faceVertexUvs[0][i][j] = new Vector2(u, v)
            }
        }

        this.points.geometry.merge(point.geometry, point.matrix)
    }

    resize = (width, height) => {
        this.width = width
        this.height = height
        // this.camera.aspect = this.width / this.height
        // this.camera.updateProjectionMatrix()

        // const scale = (1 / 1920) * width
        // this.rotationObj.position.x = (-19 / 1920) * width

        this.rotationObj.scale.x = this.rotationObj.scale.y = this.rotationObj.scale.z = 2

        if (!this.dims) return

        const targetWidth = this.dims.width * 2
        const targetHeight = targetWidth

        this.renderPlane.material.uniforms.resolution.value = new Vector2(
            1 / targetWidth,
            1 / targetHeight
        )

        // console.log(targetWidth, targetHeight)
        this.renderTarget.setSize(targetWidth, targetHeight)
    }

    setPos = data => {
        if (!data) return
        const { innerWidth, innerHeight } = window
        const { width, height, top, left } = data

        if (!this.dims) {
            this.dims = data
            this.resize(innerWidth, innerHeight)
        }

        this.dims = data

        // this.

        const x = (left + width / 2 - innerWidth / 2) / innerWidth

        this.renderPlane.position.x = x
        this.renderPlane.scale.x = (1 / innerWidth) * width
        this.renderPlane.scale.y = (1 / innerHeight) * width
    }

    update = (progress, posData) => {
        if (!this.canRender) return

        this.setPos(posData)

        const op = rp(progress, 0, 0.4) - rp(progress, 0.8, 1)

        this.renderPlane.material.uniforms.opacity.value = op

        const t = window.performance.now() * 0.0001

        this.material.uniforms.time.value = t

        this.camera.position.x = 0
        this.camera.position.y = 80
        this.camera.position.z = -5 + 10 * progress

        this.camera.lookAt(new Vector3(0, 0, 0))
        this.rotationObj.position.y = 5

        this.camera.rotation.z = 0

        this.renderPlane.position.y = -0.05 + 0.1 * progress

        this.animMouse.x += (this.mouse.x - this.animMouse.x) * 0.1
        this.animMouse.y += (this.mouse.y - this.animMouse.y) * 0.1

        this.rotationObj.rotation.z = this.animMouse.x * 0.3
        this.rotationObj.rotation.x = this.animMouse.y * 0.3

        // this.rotationObj.rotation.z = -0.1

        this.infectUpdate()
    }

    render = renderer => {
        if (!this.canRender) return

        renderer.setRenderTarget(this.renderTarget)
        renderer.clear()
        renderer.render(this.scene, this.camera)
        renderer.setRenderTarget(null)
    }
}

export default Crowdsourced
