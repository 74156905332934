import React, { createContext, useContext, useState } from "react"

const WebGLContext = createContext()
const { Provider } = WebGLContext

export const useWebGLContext = () => {
    return useContext(WebGLContext)
}

export const WebGLProvider = ({ children }) => {
    const [data, setData] = useState({
        tiles: [],
        trackers: [],
        cover: null,
        active: false,
    })

    const obj = {
        data,
        setData,
    }

    return <Provider value={obj}>{children}</Provider>
}

export default WebGLContext
