import React, { useEffect, useRef, useContext, useState } from "react"

import { useWebGLContext } from "../contexts/WebGLContext"
import { relativeProgress, clamp } from "../../util/math"

import Stage from "./Stage"
import { af as AF } from "@gladeye/af"
import { Helmet } from "react-helmet"
import preload1 from "./models/gridLand3.obj"
import preload2 from "./models/hex.obj"
import preload3 from "./images/worldMask.jpg"
import preload4 from "./images/worldData.jpg"
import preload5 from "./models/usa3.obj"
import preload6 from "./models/hex.obj"
import preload7 from "./images/us-pop2.png"
const WebGLAnimations = props => {
    const ref = useRef()
    const webglContext = useWebGLContext()
    const { data, setData } = webglContext
    const [isVisible, setVisible] = useState(true)
    const stageRef = useRef()

    useEffect(() => {
        stageRef.current = new Stage({ el: ref.current })
    }, [])

    useEffect(() => {
        const { tiles, cover, active, trackers } = data
        const stage = stageRef.current

        if (!active) {
            setVisible(false)
            stage.renderer.setClearColor(0x1b1839, 1)
            stage.renderer.setRenderTarget(null)
            stage.renderer.clear()
            return
        } else {
            setVisible(true)
            stage.renderer.setClearColor(0x1b1839, 0)
        }

        // console.log("trigger")

        stage.init()

        const af = AF()

        const read = () => {
            const { innerHeight: wh } = window
            const { top, height } = cover.getBoundingClientRect()
            let prog = clamp(Math.abs(top / wh), 0, 1)
            let max = 1 + Math.min(tiles.length, 2)
            let tileDims = []

            tiles.forEach(function (tile, i) {
                if (i > 1) return

                const {
                    top,
                    height,
                    width,
                    left,
                } = tile.getBoundingClientRect()

                const tracker = trackers[i]
                const { top: tt, height: th } = tracker.getBoundingClientRect()
                let val = clamp((tt / th) * -1, 0, 1)

                // val = ((tt - wh * 0.5) * -1) / th

                tileDims.push({ top, height, width, left })

                if (val > 0 && val < 1) {
                    prog = 1 + i + val

                    // console.log(prog)
                } else if (val > 0) {
                    prog = 2 + i
                }
            })

            // console.log(prog)

            stage.update(prog, max, tileDims)
        }

        const write = () => {
            stage.write()
        }

        if (window.innerWidth >= 1024) {
            af.addRead(read)
            af.addWrite(write)
        }

        const resize = () => {
            stage.resize()

            af.removeRead(read)
            af.removeWrite(write)
            stage.renderer.clear()

            if (window.innerWidth >= 1024) {
                af.addRead(read)
                af.addWrite(write)
            }
        }

        window.addEventListener("resize", resize)

        return () => {
            af.removeRead(read)
            af.removeWrite(write)

            window.removeEventListener("resize", resize)

            stage.deactivate()
        }
    }, [data])

    return (
        <div
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "#1b1839",
            }}
        >
            <Helmet>
                <link
                    rel={"preload"}
                    href={preload1}
                    as={"fetch"}
                    crossOrigin={"anonymous"}
                />
                <link
                    rel={"preload"}
                    href={preload2}
                    as={"fetch"}
                    crossOrigin={"anonymous"}
                />
                <link
                    rel={"preload"}
                    href={preload3}
                    as={"image"}
                    crossOrigin={"anonymous"}
                />
                <link
                    rel={"preload"}
                    href={preload4}
                    as={"image"}
                    crossOrigin={"anonymous"}
                />
                <link
                    rel={"preload"}
                    href={preload5}
                    as={"fetch"}
                    crossOrigin={"anonymous"}
                />
                <link
                    rel={"preload"}
                    href={preload6}
                    as={"fetch"}
                    crossOrigin={"anonymous"}
                />
                <link
                    rel={"preload"}
                    href={preload7}
                    as={"image"}
                    crossOrigin={"anonymous"}
                />
            </Helmet>
            <canvas
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    visibility: isVisible ? "visible" : "hidden",
                }}
                ref={ref}
            ></canvas>
        </div>
    )
}

export default WebGLAnimations
