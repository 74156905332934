import { hydrate, render } from "react-dom"
import { loadableReady } from "@loadable/component"
// import React from "react";

export const replaceHydrateFunction = () => (element, container, callback) => {
    loadableReady(() => {
        const renderFn = process.env.GATSBY_BUILD_STAGE.includes("develop")
            ? render
            : hydrate
        renderFn(element, container, callback)
    })
}

const transitionDelay = 300
export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    if (location.action === "PUSH") {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
    } else {
        const savedPosition = getSavedScrollPosition(location)
        window.setTimeout(
            () => window.scrollTo(...(savedPosition || [0, 0])),
            transitionDelay
        )
    }
    return false
}

export const onRouteUpdate = () => {
    if (process.env.NODE_ENV === "production") {
        // wrap inside a timeout to ensure the title has properly been changed
        setTimeout(() => {
            let data = window.dataLayer || []
            let event = "virtualPageView"

            data.push({ event })
        }, 100)
    }
}

// const scrollTo = id => () => {
//   const el = document.querySelector(id);
//   if (el) {
//     let rect = el.getBoundingClientRect();
//     return window.scrollTo(
//       0,
//       rect.top + window.scrollY + (rect.height - window.innerHeight) / 2
//     );
//   }
//   return false;
// };
