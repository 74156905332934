import vertexShader from "./crowd.vert"
import fragmentShader from "./crowd.frag"

export default {
    uniforms: {
        tDiffuse: { value: null },
        textureData: { value: null },
        extrudeMin: { type: "f", value: 0 },
        extrudeMax: { type: "f", value: 20 },
        time: { type: "f", value: 0 },
        infect: { value: 0 },
        infectPoint: { value: [0, 0] },
    },
    vertexShader,
    fragmentShader,
}
