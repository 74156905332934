import {
    WebGLRenderTarget,
    LinearFilter,
    NearestFilter,
    RGBAFormat,
    FloatType,
} from 'three';

class FBO extends WebGLRenderTarget{
    constructor({width = window.innerWidth, height = window.innerHeight , type = false, minFilter = false, magFilter = false}){


        const opts = {
            minFilter: minFilter || LinearFilter,
            magFilter: magFilter || LinearFilter,
            format: RGBAFormat,
            type: type || FloatType
        }

        super(width, height, opts);
    }
}

export default FBO;