import {
    LinearFilter,
    RGBAFormat,
    Points,
    Scene,
    BufferGeometry,
    BufferAttribute,
    DataTexture,
    FloatType,
    NearestFilter,
    RawShaderMaterial,
    PlaneGeometry,
    ShaderMaterial,
    Mesh,
    Color,
} from "three"

import GPULoop from "./GPULoop"

import velocityFrag from "../shaders/particleDriftVelocity.frag"
import movementFrag from "../shaders/particleDriftMovement.frag"
import particleDriftShader from "../shaders/particleDrift"
import vert from "../shaders/test.vert"

import imageLoader from "../imageLoader"
import hexImage from "../images/hex.png"
import driftShaders from "../shaders/drift"

import OrthCam from "./OrthCam"

class ParticleDrift {
    constructor() {
        this.initMaterial()
        this.initGeometry()

        this.scene = new Scene()
        // this.scene.background = new Color(0x1b1839)

        const geo = new PlaneGeometry(1, 1)
        const material = new ShaderMaterial({
            ...driftShaders,
            transparent: true,
        })

        this.bg = new Mesh(geo, material)

        this.points = new Points(this.geometry, this.material)
        this.scene.add(this.points, this.bg)

        this.camera = new OrthCam(1, 1)
    }

    get dataTexture() {
        return new DataTexture(new Float32Array([0, 0, 0, 1]), 1, 1, RGBAFormat)
    }

    initMaterial = () => {
        const shaderOpts = particleDriftShader

        shaderOpts.uniforms.movement.value = this.dataTexture
        shaderOpts.uniforms.bounds.value = [
            window.innerWidth,
            window.innerHeight,
        ]

        this.material = new RawShaderMaterial(shaderOpts)

        imageLoader.load(hexImage).then(tex => {
            this.material.uniforms.tDiffuse.value = tex
        })
    }

    initDataTextures = (width, height) => {
        this.velocities = new GPULoop({
            width,
            height,
            frag: velocityFrag,
            textureFilter: NearestFilter,
            vert,
            input: this.posTexture,
            uniforms: {
                positions: {
                    value: this.dataTexture,
                },
                movement: {
                    value: this.dataTexture,
                },

                bounds: {
                    value: [window.innerWidth, window.innerHeight],
                },
            },
        })

        this.movement = new GPULoop({
            width,
            height,
            frag: movementFrag,
            vert,
            textureFilter: LinearFilter,
            // input: this.posTexture,
            uniforms: {
                velocities: {
                    value: this.dataTexture,
                },

                bounds: {
                    value: [window.innerWidth, window.innerHeight],
                },
            },
        })
    }

    initGeometry = () => {
        const { innerWidth: ow, innerHeight: oh } = window

        const w = 16
        const h = 16

        const vertices = []
        const dataPos = []

        const texVerts = new Float32Array(4 * w * h)

        for (let y = 0; y < h; y++) {
            for (let x = 0; x < w; x++) {
                const nx = 0 //Math.random() - 0.5
                const ny = 0 //Math.random() - 0.5

                vertices.push(nx, ny, 0)
                dataPos.push(x / w, y / h)

                const n = (y * w + x) * 4

                texVerts[n] = nx
                texVerts[n + 1] = ny
                texVerts[n + 2] = 1
                texVerts[n + 3] = 1

                // console.log(nx, ny)
            }
        }

        this.posTexture = new DataTexture(texVerts, w, h, RGBAFormat, FloatType)
        this.posTexture.needsUpdate = true

        this.initDataTextures(w, h)

        this.movement.uniforms.velocities.value = this.velocities.target
        // this.movement.uniforms.dataIn.value = this.posTexture
        // this.movement.uniforms.movement.value = this.posTexture

        this.velocities.uniforms.movement.value = this.movement.target

        this.material.uniforms.movement.value = this.movement.target

        this.geometry = new BufferGeometry()

        this.geometry.addAttribute(
            "position",
            new BufferAttribute(new Float32Array(vertices), 3)
        )
        // this.geometry.addAttribute( 'texPos', new BufferAttribute( new Float32Array(relPos), 2 ) );
        this.geometry.addAttribute(
            "dataPos",
            new BufferAttribute(new Float32Array(dataPos), 2)
        )
    }

    update = renderer => {
        this.movement.uniforms.time.value =
            (window.performance.now() / 5000) % 1

        this.material.uniforms.movement.value = this.movement.render(renderer)
        this.movement.uniforms.velocities.value = this.velocities.render(
            renderer
        )
    }

    render = (renderer, target = null) => {
        this.material.uniforms.time.value = window.performance.now() / 5000
        renderer.clearDepth()
        renderer.setRenderTarget(target)
        if (target) renderer.clear()
        renderer.render(this.scene, this.camera)

        // console.log('render')
    }

    resize = (width, height) => {
        this.camera.resize(1, 1)
        this.velocities.uniforms.bounds.value = [width, height]
        this.movement.uniforms.bounds.value = [width, height]
        this.material.uniforms.bounds.value = [width, height]

        const w = 16
        const h = 16

        const texVerts = new Float32Array(4 * w * h)

        for (let y = 0; y < h; y++) {
            for (let x = 0; x < w; x++) {
                const nx = (x / w) * 2 - 1 + Math.random() * 0.2 - 0.1
                const ny = (y / h) * 2 - 1 + Math.random() * 0.2 - 0.1 // Math.random() * 2 - 1
                const n = (y * w + x) * 4

                texVerts[n] = nx
                texVerts[n + 1] = ny
                texVerts[n + 2] = 1
                texVerts[n + 3] = 1
            }
        }

        this.posTexture.dispose()
        this.posTexture = new DataTexture(texVerts, w, h, RGBAFormat, FloatType)
        this.posTexture.needsUpdate = true

        this.movement.uniforms.dataIn.value = this.posTexture
    }
}

export default ParticleDrift
