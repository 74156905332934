import { FXAAShader } from "./FXAAShader"

const { uniforms, vertexShader, fragmentShader } = FXAAShader

const shader = {
    uniforms,
    vertexShader,
    fragmentShader,
}

export default shader
