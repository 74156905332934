import vertexShader from "./atmosphere.vert"
import fragmentShader from "./atmosphere.frag"

export default {
    uniforms: {
        atmosPosY: { value: 0 },
    },
    vertexShader,
    fragmentShader,
}
