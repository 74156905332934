import {
    OrthographicCamera
} from 'three';


class OrthCam extends OrthographicCamera{

    _instance;

    static instance(){
        if(this._instance) return this._instance;
        return this._instance = new this();
    }

    constructor(w = null, h = null){

        const nw = w || window.innerWidth;
        const nh = h || window.innerHeight;

        super(nw / - 2, nw / 2, nh / 2, nh / - 2, -1000, 1000);
        this.position.z = 1;
    }

    resize(w = null, h = null){

        const nw = w || window.innerWidth;
        const nh = h || window.innerHeight;

        this.left = nw / - 2;
        this.right = nw / 2;
        this.top = nh / 2;
        this.bottom = nh / - 2;
        this.updateProjectionMatrix();
    }

}

export default OrthCam;