import vertexShader from "./stage.vert"
import fragmentShader from "./drift.frag"

export default {
    uniforms: {
        tDiffuse: { value: null },
        opacity: { value: 1 },
    },
    vertexShader,
    fragmentShader,
}
