const { useContext, createElement, createContext } = require('react');
const GraphQlSourceContext = createContext();

exports.useGraphQlSource = function useGraphQlSource() {
  const context = useContext(GraphQlSourceContext);
  if (!context) {
    throw new Error('`useGraphQlSource` must be used within `GraphQlSourceProvider`');
  }
  return context;
};

exports.default = function GraphQlSourceProvider({ sourceOptions, ...props }) {
  return createElement(GraphQlSourceContext.Provider, { value: sourceOptions, ...props });
};
