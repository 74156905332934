import vertexShader from "./test.vert"
import fragmentShader from "./test.frag"

export default {
    uniforms: {
        tDiffuse: { value: null },
    },
    vertexShader,
    fragmentShader,
}
