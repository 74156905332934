import { OBJLoader } from "../../../node_modules/three/examples/jsm/loaders/OBJLoader"

class ModelLoader {
    loader = new OBJLoader()
    models = {}

    constructor() {}

    load = (model, resolve) => {
        const modelObj = this.models[model]

        if (modelObj) {
            if (modelObj.loaded) resolve(modelObj.model)
            else modelObj.onLoad.push(resolve)

            return
        }

        this.models[model] = {
            _model: null,
            onLoad: [],
            loaded: false,
            get model() {
                return this._model.clone()
            },
            set model(val) {
                this._model = val
            },
        }

        this.models[model].onLoad.push(resolve)

        this.loader.load(model, result => {
            this.models[model].loaded = true
            this.models[model].model = result
            this.models[model].onLoad.forEach(fn => {
                fn(this.models[model].model)
            })
        })
    }
}

export default new ModelLoader()
