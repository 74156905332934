import vertexShader from "./earth.vert"
import fragmentShader from "./earth.frag"

export default {
    uniforms: {
        tDiffuse: { value: null },
    },
    vertexShader,
    fragmentShader,
}
